<form [formGroup]="groupForm" [bitSubmit]="submit">
  <bit-dialog [disablePadding]="!loading">
    <span bitDialogTitle>
      {{ title }}
      <span *ngIf="editMode" class="tw-text-sm tw-normal-case tw-text-muted">{{
        group?.name
      }}</span>
    </span>
    <div bitDialogContent>
      <div *ngIf="loading">
        <i
          class="bwi bwi-spinner bwi-spin text-muted"
          title="{{ 'loading' | i18n }}"
          aria-hidden="true"
        ></i>
        <span class="sr-only">{{ "loading" | i18n }}</span>
      </div>

      <bit-tab-group *ngIf="!loading" [(selectedIndex)]="tabIndex">
        <bit-tab label="{{ 'groupInfo' | i18n }}">
          <bit-form-field>
            <bit-label>{{ "name" | i18n }}</bit-label>
            <input bitInput appAutofocus type="text" formControlName="name" />
            <bit-hint>{{ "characterMaximum" | i18n : 100 }}</bit-hint>
          </bit-form-field>
          <bit-form-field>
            <bit-label>{{ "externalId" | i18n }}</bit-label>
            <input bitInput type="text" formControlName="externalId" />
            <bit-hint>{{ "externalIdDesc" | i18n }}</bit-hint>
          </bit-form-field>
        </bit-tab>

        <bit-tab label="{{ 'members' | i18n }}">
          <p>{{ "editGroupMembersDesc" | i18n }}</p>
          <bit-access-selector
            formControlName="members"
            [items]="members"
            [showMemberRoles]="true"
            [permissionMode]="PermissionMode.Hidden"
            [columnHeader]="'member' | i18n"
            [selectorLabelText]="'selectMembers' | i18n"
            [emptySelectionText]="'noMembersAdded' | i18n"
          ></bit-access-selector>
        </bit-tab>

        <bit-tab label="{{ 'collections' | i18n }}">
          <p>{{ "editGroupCollectionsDesc" | i18n }}</p>
          <div class="tw-my-3">
            <input type="checkbox" formControlName="accessAll" id="accessAll" />
            <label class="tw-mb-0 tw-text-lg" for="accessAll">{{
              "accessAllCollectionsDesc" | i18n
            }}</label>
            <p class="tw-my-0 tw-text-muted">{{ "accessAllCollectionsHelp" | i18n }}</p>
          </div>
          <ng-container *ngIf="!groupForm.value.accessAll">
            <bit-access-selector
              formControlName="collections"
              [items]="collections"
              [permissionMode]="PermissionMode.Edit"
              [columnHeader]="'collection' | i18n"
              [selectorLabelText]="'selectCollections' | i18n"
              [emptySelectionText]="'noCollectionsAdded' | i18n"
            ></bit-access-selector>
          </ng-container>
        </bit-tab>
      </bit-tab-group>
    </div>
    <div bitDialogFooter class="tw-flex tw-flex-row tw-gap-2">
      <button bitButton buttonType="primary" bitFormButton type="submit">
        {{ "save" | i18n }}
      </button>
      <button
        bitButton
        buttonType="secondary"
        type="button"
        bitDialogClose
        [bit-dialog-close]="ResultType.Canceled"
      >
        {{ "cancel" | i18n }}
      </button>
      <button
        class="tw-ml-auto"
        type="button"
        buttonType="danger"
        bitIconButton="bwi-trash"
        bitFormButton
        [bitAction]="delete"
        [appA11yTitle]="'delete' | i18n"
      ></button>
    </div>
  </bit-dialog>
</form>
