<form [formGroup]="formGroup" [bitSubmit]="submit">
  <bit-dialog dialogSize="small">
    <span bitDialogTitle>
      {{ "moveSelected" | i18n }}
    </span>
    <span bitDialogContent>
      <p>{{ "moveSelectedItemsDesc" | i18n : cipherIds.length }}</p>
      <bit-form-field>
        <bit-label for="folder">{{ "folder" | i18n }}</bit-label>
        <select bitInput formControlName="folderId">
          <option *ngFor="let f of folders$ | async" [ngValue]="f.id">{{ f.name }}</option>
        </select>
      </bit-form-field>
    </span>
    <div bitDialogFooter class="tw-flex tw-flex-row tw-gap-2">
      <button bitButton bitFormButton type="submit" buttonType="primary">
        {{ "save" | i18n }}
      </button>
      <button bitButton bitFormButton type="button" buttonType="secondary" (click)="cancel()">
        {{ "cancel" | i18n }}
      </button>
    </div>
  </bit-dialog>
</form>
