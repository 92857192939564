<div class="form-group">
  <div class="form-check">
    <input
      class="form-check-input"
      type="checkbox"
      [attr.id]="controlId"
      [attr.aria-describedby]="describedById"
      [formControl]="internalControl"
      (blur)="onBlurInternal()"
    />
    <label class="form-check-label" [attr.for]="controlId">{{ label }}</label>
  </div>
  <small *ngIf="showDescribedBy" [attr.id]="describedById" class="form-text text-muted">{{
    helperText
  }}</small>
</div>
